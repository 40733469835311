<template>
  <div class="outer-page">
    <div class="sub-section">
      <div class="warpper">
        <div class="sub-info">
          <div v-html="list.articleContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  computed: {
    //获取菜单
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    //获取当前的router
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 10,
          pageNo: 1,
        }).then((res) => {
          this.list = res;
        });
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>