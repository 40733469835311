<template>
  <div class="outer-page">
    <article-info></article-info>
  </div>
</template>

<script>
import articleInfo from "@components/articleInfo"
export default {
  name: "",
   components: {
    articleInfo,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
}
</style>